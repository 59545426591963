<template>
  <div id="tracking">
    <Appbar />
    <v-progress-linear
      :indeterminate="loading"
      :active="loadingActive"
      color="white"
      style="margin-top: 100px"
    ></v-progress-linear>
    <v-container grid-list-xs class="container-tracking px-4">
      <div class="btn-back" @click.prevent="$router.back()">ย้อนกลับ</div>
      <div class="wrapper-result-tracking">
        <div class="result-title">
          <h2>ผลลัพธ์การค้นหาจากเบอร์ "{{ params.phoneNumber }}"</h2>
          <span>{{ totalElement }} ผลลัพธ์</span>
        </div>
        <div class="wrapper-tracking-number mt-10">
          <div
            class="tracking-number-item mb-4"
            v-for="(data, index) in trackingOrderCustomer"
            :key="index"
          >
            <div
              class="
                info-tracking-line1
                d-md-flex
                justify-md-space-between
                align-center
              "
            >
              <div class="info-fullname mb-2">
                {{ index + 1 + (pages * params.limit - params.limit) }}. คุณ :
                {{ data.first_name }} {{ data.last_name }}
              </div>
              <div class="info-phone-number mb-2">
                เบอร์โทรศัพท์ : {{ data.phone }}
              </div>
            </div>
            <div class="info-tracking-line2 mb-2">
              บริการขนส่ง : {{ data.logistics }}
            </div>
            <div class="info-tracking-line2 mb-2">
              วันที่จัดส่ง : {{ data.date | formatDate }}
            </div>
            <div
              class="
                info-tracking-line3
                mb-4
                d-flex
                align-center
                justify-center
              "
            >
              Tracking Number :
              <span class="ml-2 mr-1">{{ data.trackingNumber }}</span>
              <v-btn
                small
                icon
                @click.prevent="copyTracking(data.trackingNumber)"
                ><v-icon small color="#fff">mdi-content-copy</v-icon></v-btn
              >
            </div>
            <v-divider class="mb-2" color="#fff"></v-divider>
            <div class="info-tracking-line4">
              <v-expansion-panels class="expan-product-view" flat dark>
                <v-expansion-panel v-for="(item, i) in 1" :key="i">
                  <v-expansion-panel-header>
                    ดูชื่อสินค้าที่สั่ง ({{ data.product_name.length }} รายการ)
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background: #383e45">
                    <ul>
                      <li v-for="(item, i) in data.product_name" :key="i">
                        {{ item }}
                      </li>
                    </ul>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
        </div>
        <div
          style="font-size: 20px"
          class="d-flex flex-column align-center red--text my-4"
          v-if="trackingOrderCustomer.length === 0 && loading === false"
        >
          <v-img
            class="mt-2 mb-4"
            width="200"
            src="@/assets/no-data.svg"
            alt=""
          >
          </v-img>
          ไม่พบข้อมูลรายการ Tracking Number
        </div>
        <div class="mt-2 mb-14" v-if="trackingOrderCustomer.length > 0">
          <v-pagination
            v-model="params.page"
            :length="totalPages"
            :total-visible="7"
            @input="getTrackingOrderCustomer"
          ></v-pagination>
        </div>
      </div>
    </v-container>
    <div class="wrapper-icon-logistics text-center">
      <v-menu top :offset-y="offset">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on" fab>
            <img width="35px" src="../assets/delivery-truck.png" alt="" />
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-for="(item, index) in items" :key="index" link>
            <v-list-item-title
              ><a :href="item.to" target="_blank">{{ item.title }}</a>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <Footer />
  </div>
</template>

<script>
import Appbar from "@/components/Appbar";
import Footer from "@/components/Footer";
import TrackingService from "@/services/Tracking.service";
import Swal from "sweetalert2";

import moment from "moment";
export default {
  name: "Tracking",
  components: {
    Appbar,
    Footer,
  },
  data() {
    return {
      loading: false,
      loadingActive: false,
      trackingOrderCustomer: [],
      totalPages: 1,
      totalElement: 0,
      totalCustomer: 0,
      pages: 1,
      params: {
        phoneNumber: this.$route.query.phone,
        page: 1,
        limit: 20,
      },

      items: [
        {
          title: "ติดตามพัสดุที่ Kerry",
          to: "https://th.kerryexpress.com/th/home",
        },
        {
          title: "ติดตามพัสดุที่ EMS",
          to: "https://track.thailandpost.co.th/",
        },
        {
          title: "ติดตามพัสดุที่ J&T",
          to: "https://www.jtexpress.co.th/index/query/gzquery.html",
        },
      ],
      offset: true,
    };
  },
  created() {
    this.getTrackingOrderCustomer();
  },
  filters: {
    formatDate(date) {
      return moment(date).format("DD MMMM YYYY");
    },
  },
  methods: {
    async getTrackingOrderCustomer() {
      this.loading = true;
      this.loadingActive = true;
      try {
        const response = await TrackingService.findCustomerAll(this.params);
        this.loading = false;
        this.loadingActive = false;
        this.trackingOrderCustomer = response.orders;
        this.totalPages = response.totalPages;
        this.totalElement = response.totalElement;
        this.totalCustomer = response.totalCustomer;
        this.pages = this.params.page;
      } catch (error) {
        this.loading = false;
        this.loadingActive = false;
        Swal.fire({
          icon: "error",
          title: error.msg,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
    copyTracking(text) {
      navigator.clipboard.writeText(text);
    },
  },
};
</script>

<style scoped>
.container-tracking {
  margin-top: 40px;
}
.btn-back {
  color: rgb(228, 228, 228);
  text-decoration: underline #fff !important;
  cursor: pointer;
}
.result-title h2 {
  color: #fff;
  font-size: 42px;
}
.result-title span {
  color: #fff;
}
.tracking-number-item {
  width: 100%;
  background: #383e45;
  padding: 1rem;
  border-radius: 4px;
}
.info-tracking-line1 .info-fullname {
  color: #fff;
  font-size: 28px;
  font-weight: 500;
}
.info-tracking-line1 .info-phone-number {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
}
.info-tracking-line2 {
  color: #fff;
  font-size: 16px;
}
.info-tracking-line3 {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
}
.v-application--is-ltr .v-expansion-panel-header {
  background: #383e45 !important;
  color: #fff;
  border-radius: 0px !important;
}
@media only screen and (max-width: 800px) {
  .result-title h2 {
    font-size: 32px;
  }
}
@media only screen and (max-width: 560px) {
  .result-title h2 {
    font-size: 30px;
  }
  .info-tracking-line1 .info-fullname {
    font-size: 22px;
  }
  .info-tracking-line1 .info-phone-number {
    font-size: 18px;
  }
  .info-tracking-line2 {
    font-size: 14px;
  }
  .info-tracking-line3 {
    font-size: 16px;
  }
}
@media only screen and (max-width: 560px) {
  .result-title h2 {
    font-size: 26px;
  }
  .info-tracking-line1 .info-fullname {
    font-size: 18px;
  }
  .info-tracking-line1 .info-phone-number {
    font-size: 15px;
  }
  .info-tracking-line2 {
    font-size: 13px;
  }
  .info-tracking-line3 {
    font-size: 14px;
  }
}

.wrapper-icon-logistics {
  position: fixed;
  bottom: 1.5rem;
  right: 2rem;
  z-index: 10;
}
</style>
